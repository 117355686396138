import React from 'react';
import { request, request_file } from './request.js';
import './Principal.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import Swal from 'sweetalert2';

class Principal extends React.Component
{
	constructor(props){
		super(props);

		this.state={
			fichas : [],
			cargando : 0,
			ajuste : 0
		}
	}

	componentDidMount()
	{
		this.mostrar_lista('4');
	}

	handleInputChange=(event)=>
	{
		const target = event.target;
		const value = target.value;
		if( (value!=="2") && (value!=="7") ) this.mostrar_lista(value);
	}

	mostrar_lista=(op)=>
	{
		var { fichas, opcion, cargando, ajuste } = this.state;
		let temp = this;

		cargando = 1;

		opcion = op;
		ajuste = 0;

		switch(opcion) {
			case '1':
				request.get('/TodasLasFichas')
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							fichas = response.data;
							cargando = 0;
							temp.setState({ fichas : fichas, cargando : cargando });
						}
					}
				})
				.catch(error => { });
			break;
			//------------------------------------------------------------------
			case '3':
				request.get('/FichasSinFoto')
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							fichas = response.data;
							cargando = 0;
							temp.setState({ fichas : fichas, cargando : cargando });
						}
					}
				})
				.catch(error => { });
			break;
			case '4':
				request.get('/FotosPendientes')
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							fichas = response.data;
							cargando = 0;
							temp.setState({ fichas : fichas, cargando : cargando });
						}
					}
				})
				.catch(error => { });
			break;
			case  '5':
			case '15':
			case '16':
			case '17':
			case '18':
			case '19':
			case '20':
			case '21':
			case '22':
			case '23':
			case '24':
			case '25':
			case '26':
			case '27':
			case '28':
			case '29':
			case '30':
			case '31':
			case '32':
			case '33':
			case '34':
			case '35':
			case '36':
			case '37':
			case '38':
			case '39':
			case '40':
			case '41':
			case '42':
			ajuste = (opcion-15)*50
				request.get('/FotosAutorizadas/'+opcion)
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							fichas = response.data;
							cargando = 0;
							temp.setState({ fichas : fichas, cargando : cargando, ajuste : ajuste });
						}
					}
				})
				.catch(error => { });
			break;
			case '6':
				request.get('/FotosRechazadas')
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							fichas = response.data;
							cargando = 0;
							temp.setState({ fichas : fichas, cargando : cargando });
						}
					}
				})
				.catch(error => { });
			break;
			//------------------------------------------------------------------
			case '8':
				request.get('/FichasSinComprobante')
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							fichas = response.data;
							cargando = 0;
							temp.setState({ fichas : fichas, cargando : cargando });
						}
					}
				})
				.catch(error => { });
			break;
			case '9':
				request.get('/ComprobantesPendientes')
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							fichas = response.data;
							cargando = 0;
							temp.setState({ fichas : fichas, cargando : cargando });
						}
					}
				})
				.catch(error => { });
			break;
			case '10':
				request.get('/ComprobantesAutorizados')
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							fichas = response.data;
							cargando = 0;
							temp.setState({ fichas : fichas, cargando : cargando });
						}
					}
				})
				.catch(error => { });
			break;
			case '11':
				request.get('/ComprobantesRechazados')
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							fichas = response.data;
							cargando = 0;
							temp.setState({ fichas : fichas, cargando : cargando });
						}
					}
				})
				.catch(error => { });
			break;
			//------------------------------------------------------------------
			
			case '13':
				request.get('/CuentasBloqueadas')
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							fichas = response.data;
							cargando = 0;
							temp.setState({ fichas : fichas, cargando : cargando });
						}
					}
				})
				.catch(error => { });
			break;
			default:
		}

		this.setState({ cargando : cargando, opcion : opcion });
	}

	autorizar(event,pos,id)
	{
		event.preventDefault();

		var { fichas } = this.state;
		let temp = this;

		request.get(`/AutorizarFoto/${id}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					fichas[pos].estadoFoto = 3;
					temp.setState({ fichas : fichas });
				}
			}
		})
		.catch(error => { });
	}

	rechazar(event,pos,id)
	{
		event.preventDefault();
		var { fichas } = this.state;
		let temp = this;

		request.get(`/RechazarFoto/${id}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					fichas[pos].estadoFoto = 4;
					temp.setState({ fichas : fichas });
				}
			}
		})
		.catch(error => { });
	}

	verComprobante(event,pos,folio)
	{
		event.preventDefault();

		request_file()
		.post(`/verComprobante?folio=${folio}`)
		.then(response => {
			const file = new Blob([response.data], {type: 'image/jpg'});
			const fileURL = URL.createObjectURL(file);
			Swal.fire({
				imageUrl: fileURL,
				imageHeight: 700,
				imageAlt: 'A tall image'
			});

		})
		.catch(error => {
			console.log(error);
		});	
	}

	autorizarComprobante(event,pos,id)
	{
		event.preventDefault();

		var { fichas } = this.state;
		let temp = this;

		request.get(`/AutorizarComprobante/${id}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					fichas[pos].check4 = 3;
					temp.setState({ fichas : fichas });
				}
			}
		})
		.catch(error => { });
	}

	rechazarComprobante(event,pos,id)
	{
		event.preventDefault();

		var { fichas } = this.state;
		let temp = this;

		request.get(`/RechazarComprobante/${id}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					fichas[pos].check4 = 4;
					temp.setState({ fichas : fichas });
				}
			}
		})
		.catch(error => { });
	}

	render(){

		let { fichas, opcion, cargando, ajuste } = this.state;

		return (
			<div>
				<div className="titulo"> 
					R E P O R T E &nbsp;&nbsp;&nbsp; D E &nbsp;&nbsp;&nbsp; F I C H A S &nbsp;&nbsp;&nbsp; S O L I C I T A D A S
				</div>

				<div className="botones">

					<div className="input-group mb-3">
						<div className="input-group-prepend">
							<label className="input-group-text" htmlFor="filtros">Mostrar</label>
						</div>
						
						<select className ="custom-select" name="filtros" value={opcion} id="filtros" onChange={this.handleInputChange}>
							<option value =  "1"> Todas las fichas </option>
							<option value =  "2"> - - - - - - - - - - - </option>
							<option value =  "3"> Fichas sin foto </option>
							<option value =  "4"> Fotos por autorizar </option>
							{/*<option value =  "5"> Fotos autorizadas </option>*/}
							<option value =  "6"> Fotos rechazadas </option>
							<option value =  "7"> - - - - - - - - - - - </option>
							{/*<option value =  "8"> Fichas sin comprobante </option>*/}
							{/*<option value =  "9"> Comprobantes por autorizar </option>*/}
							{/*<option value = "10"> Comprobantes autorizados </option>*/}
							{/*<option value = "11"> Comprobantes rechazados </option>*/}
							{/*<option value = "12"> - - - - - - - - - - - </option>*/}
							{/*<option value = "13"> Cuentas bloqueadas </option>*/}
							<option value =  "15"> Fotos autorizadas (1 - 50) </option>
							<option value =  "16"> Fotos autorizadas (51 - 100) </option>
							<option value =  "17"> Fotos autorizadas (101 - 150) </option>
							<option value =  "18"> Fotos autorizadas (151 - 200) </option>
							<option value =  "19"> Fotos autorizadas (201 - 250) </option>
							<option value =  "20"> Fotos autorizadas (251 - 300) </option>
							<option value =  "21"> Fotos autorizadas (301 - 350) </option>
							<option value =  "22"> Fotos autorizadas (351 - 400) </option>
							<option value =  "23"> Fotos autorizadas (401 - 450) </option>
							<option value =  "24"> Fotos autorizadas (451 - 500) </option>
							<option value =  "25"> Fotos autorizadas (501 - 550) </option>
							<option value =  "26"> Fotos autorizadas (551 - 600) </option>
							<option value =  "27"> Fotos autorizadas (601 - 650) </option>
							<option value =  "28"> Fotos autorizadas (651 - 700) </option>
							<option value =  "29"> Fotos autorizadas (701 - 750) </option>
							<option value =  "30"> Fotos autorizadas (751 - 800) </option>
							<option value =  "31"> Fotos autorizadas (801 - 850) </option>
							<option value =  "32"> Fotos autorizadas (851 - 900) </option>
							<option value =  "33"> Fotos autorizadas (901 - 950) </option>
							<option value =  "34"> Fotos autorizadas (951 - 1,000) </option>
							<option value =  "35"> Fotos autorizadas (1,001 - 1,050) </option>
							<option value =  "36"> Fotos autorizadas (1,051 - 1,100) </option>
							<option value =  "37"> Fotos autorizadas (1,101 - 1,150) </option>
							<option value =  "38"> Fotos autorizadas (1,151 - 1,200) </option>
							<option value =  "39"> Fotos autorizadas (1,201 - 1,250) </option>
							<option value =  "40"> Fotos autorizadas (1,251 - 1,300) </option>
							<option value =  "41"> Fotos autorizadas (1,301 - 1,350) </option>
							<option value =  "42"> Fotos autorizadas (1,351 - 1,400) </option>
						</select>
					</div>
				</div>

				{
					cargando === 1 ?
						<span className="b az mar"> Cargando... </span>
					:
						fichas.length === 0 ?
							<span className="b ro mar"> No hay registros para mostrar </span>
						:
							parseInt(opcion,10) < 43 ?
								<div className="info">
									<table>
										<thead>
											<tr className="head">
												<td className="ch"> No. </td>
												<td className="ch"> Ficha </td>
												<td className="ch"> Folio </td>
												<td className="gr"> Apellido Paterno </td>
												<td className="gr"> Apellido Materno </td>
												<td className="gr"> Nombre </td>
												<td className="md"> Imagen </td>
												<td className="md"> Estado </td>
											</tr>
										</thead>
										<tbody>
										{
											fichas.map((item,i) =>
												<tr key = { i }>
													<td className="c"> { i+1+ajuste } </td>
													<td className="c"> { item.id } </td>
													<td className="c"> { item.folio } </td>
													<td className="g"> { item.apellido_paterno } </td>
													<td className="g"> { item.apellido_materno } </td>
													<td className="g"> { item.nombre } </td>
													<td className="m">
													{
														parseInt(item.estadoFoto,10) === 1 ?
															<span className="b"> Sin Foto </span>
														:
															<img src={`data:image/jpeg;base64,${item.foto}`} alt="Foto" width="150" height="150"/> 
													}
													</td>
													<td className="m">
														{ parseInt(item.estadoFoto,10) === 2 ? <span className="am b"> Pendiente </span> : "" }
														{ parseInt(item.estadoFoto,10) === 3 ? <span className="ve b"> Autorizada </span> : "" }
														{ parseInt(item.estadoFoto,10) === 4 ? <span className="ro b"> Rechazada </span> : "" }
														<br/><br/>
														{ parseInt(item.estadoFoto,10) > 1 ? <button type="button" onClick={(e)=>this.autorizar(e,i,item.id)}> Autorizar </button> : "" }
														{ parseInt(item.estadoFoto,10) > 1 ? <button type="button" onClick={(e)=>this.rechazar(e,i,item.id)}> Rechazar </button> : "" }
													</td>
												</tr>
											)
										}
										</tbody>
									</table>
								</div>

								// COMPROBANTES -------------------------------------------------
							:
								<div className="info">
									<table>
										<thead>
											<tr className="head">
												<td className="ch"> No. </td>
												{/*<td className="ch"> ID </td>*/}
												<td className="c"> Folio </td>
												<td className="g"> Apellido Paterno </td>
												<td className="g"> Apellido Materno </td>
												<td className="g"> Nombre </td>
												<td className="m"> Comprobante </td>
												<td className="g"> Estado </td>
											</tr>
										</thead>
										<tbody>
										{
											fichas.map((item,i) =>
												<tr key = { i }>
													<td className="c"> { i+1 } </td>
													{/*<td className="c"> { item.id } </td>*/}
													<td className="c"> { item.folio } </td>
													<td className="g"> { item.apellido_paterno } </td>
													<td className="g"> { item.apellido_materno } </td>
													<td className="g"> { item.nombre } </td>
													<td className="m">
													{
														parseInt(item.check4,10) === 1 ?
															<span> Sin comprobante </span>
														:
															<button type="button" onClick={(e)=>this.verComprobante(e,i,item.folio)}> &nbsp;&nbsp;Ver&nbsp;&nbsp; </button>
													}
													</td>
													<td className="g">
														{ parseInt(item.check4,10) === 2 ? <span className="am b"> Pendiente </span> : "" }
														{ parseInt(item.check4,10) === 3 ? <span className="ve b"> Autorizada </span> : "" }
														{ parseInt(item.check4,10) === 4 ? <span className="ro b"> Rechazada </span> : "" }
														<br/>
														{ parseInt(item.check4,10) > 1 ? <button type="button" onClick={(e)=>this.autorizarComprobante(e,i,item.id)}> Autorizar </button> : "" }
														{ parseInt(item.check4,10) > 1 ? <button type="button" onClick={(e)=>this.rechazarComprobante(e,i,item.id)}> Rechazar </button> : "" }
													</td>
												</tr>
											)
										}
										</tbody>
									</table>
								</div>

				}

			</div>
		);
	}
}

export default Principal;